import {
  sharedGetInnerAlertBarState,
  envIsProd,
  envIsStageOnly,
  handleVersionHeaderError,
  logError,
  isEmpty,
  getParams,
  sortData,
  createPartnerTree,
  getAllMerchants,
  getRelationshipAndMerchantColorMap,
  useStubData,
  ignoreCase,
  snakeToTitle,
  transformData,
  envIsLocalOnly,
  getUrlObject,
  triggerLogError,
  endpoint
} from '@f1/shared/src/_helpers';
import { apiCall, fetchCall } from '@f1/shared/src/_api';
import { apiGetAllPageData } from '@f1/shared/src/_apiGetAllPageData';
import swal from 'sweetalert';
import { addToLocalDB, clearLocalDB, getFromLocalDB } from '@f1/shared/src/_dataCompression';
import { getCustomSettingsShared, saveCustomSettingsShared } from '@f1/shared/src/_customSettings';
import prevetTemplate from '@f1/shared/src/data/sharedBoarding/templates/prevetTemplate';
import { handleSignOut, sharedIsTokenValid } from '@f1/shared/src/_pollingUtils';
import { crmEndpoint, infrastructureEndpoint } from './utilsEndpoints';
import store from './redux/store';
import { getMockData } from './data/stubDataMap';
import RelationshipForestTemplate from './data/relationship/templates/RelationshipForestTemplate';
import { bankNameList } from './pages/components/forms/formHelpers';
import riskCategoryTemplate from './data/risk/landingPage/alerts/riskCategory/templates/riskCategoryTemplate';
import employeeTemplate from './data/employee/templates/employeeTemplate';
import { relationshipTemplate } from './pages/templates/relationshipTemplate';
import { mecoSettingsStructure } from './_customSettingsMeco';

export const landingPage = '/merchant';

export const createCsrfHeader = () => {
  const { authenticate = {} } = store.getState();
  const { user = {} } = authenticate || {};
  const { accessToken = {} } = user || {};
  const { csrfToken = '' } = accessToken || {};
  return {
    'x-csrf-token': csrfToken
  };
};

export const handleApiError = async (err, options = {}) => {
  const {
    requestGuidDetails = null,
    isErrorLogger = false,
    missingRequiredToken = false,
    useFullPageAlertBar = true,
    disableAllAlerts = false,
    callback = null,
    alert40x = false,
    customMessageMap = {}
  } = options || {};
  if (useFullPageAlertBar) {
    await store.dispatch({
      type: 'TOGGLE_LOADER',
      loading: false
    });
  }
  const { response } = err || {};
  const { status } = response || {};
  if ((envIsStageOnly() || envIsProd()) && status === 426) {
    // user's version header (in prod/stage only) different than the latest push
    handleVersionHeaderError();
  } else if (!useStubData && // if developing locally, don't sign out on 401
    (!isEmpty(response) && status === 401 && !alert40x && isErrorLogger !== true)
  ) {
    // signs user out of the store if they try to access a page without a token
    await store.dispatch({
      type: 'RESET_STORE'
    });
  } else if (disableAllAlerts) {
    if (callback) {
      callback();
    }
  } else if (useFullPageAlertBar) { // show full page-width alert bar
    const passedError = !isEmpty(customMessageMap) && customMessageMap[status]
      ? customMessageMap[status]
      : err;
    const { alertBarMessage } = getInnerAlertBarState({ type: 'warning', data: passedError });
    isErrorLogger !== true && await store.dispatch({
      type: 'ALERT_BAR',
      barStyle: 'warning',
      message: alertBarMessage
    });
  }

  const isVpnCheck = response?.config?.url === infrastructureEndpoint.isWhitelistedIp;

  // Don't log anything at all if the error is because the user is not on the VPN
  if (!isVpnCheck || (isVpnCheck && status === 403)) {
    // custom options to pass into logError
    const token = createCsrfHeader();
    const { authenticate = {} } = store.getState();
    const { user = {} } = authenticate;
    const userEmail = user?.identityToken?.email || localStorage.getItem('userEmail') || null;
    logError(err, {
      requestGuidDetails,
      token,
      userEmail,
      axiosRequest,
      missingRequiredToken
    });
  }
};

export const logFrontendError = (options) => {
  const { logMessage = '' } = options || {};
  const { authenticate = {} } = store.getState();
  const { user = {} } = authenticate;
  logMessage && triggerLogError({
    logMessage,
    user,
    createCsrfHeader,
    axiosRequest
  });
};

export const closeSiteModal = () => store.dispatch({ type: 'MODAL', showModal: false });

export const showSiteModal = (title, content, options) => store.dispatch({
  type: 'MODAL',
  showModal: true,
  modalTitle: title,
  modalContent: content,
  options
});

export const showSiteAlertBar = (barStyle, message, timeout) => store.dispatch({
  type: 'ALERT_BAR',
  barStyle,
  message,
  timeout
});

export const getRequestGuids = (type, options) => {
  const { activeMerchantGuid } = options || {};
  const activeRelationshipIdList = getActiveId('activeRelationshipIdList');
  const activePartnerId = getActiveId('activePartnerId');
  const relationshipGuids = !isEmpty(activeRelationshipIdList) &&
  Array.isArray(activeRelationshipIdList)
    ? activeRelationshipIdList.filter(r => !isEmpty(r.guid)).map(r => r.guid)
    : [];
  switch (type) {
    case 'merchant':
      if (!isEmpty(activeMerchantGuid?.guid)) {
        return { merchantGuid: activeMerchantGuid.guid };
      }
      break;
    case 'relationship':
      if (!isEmpty(relationshipGuids)) {
        return { relationshipIdList: relationshipGuids };
      }
      break;
    case 'partner':
      if (!isEmpty(activePartnerId?.guid)) {
        return { partnerId: activePartnerId.guid };
      }
      break;
    default:
      return null;
  }
  return null;
};

export const handleAddToLocalDB = (key, value, options) => (
  addToLocalDB(key, value, { ...options, axiosRequest })
);

export const handleClearLocalDB = () => clearLocalDB({ axiosRequest });

export const handleDecompressData = async (key) => {
  const data = (!isEmpty(key) && await getFromLocalDB(key, { axiosRequest })) || null;
  return data;
};

export const getRelationships = async (options) => {
  const {
    fullPageLoad = true
  } = options || {};
  const apiRes = await axiosRequest({
    fullPageLoad,
    url: `${crmEndpoint.relationship.root}`,
    method: 'get',
    tokenRequired: true
  });
  const formattedData = !isEmpty(apiRes?.data)
    ? transformData({
      data: { backendData: apiRes.data || {} },
      toSchema: 'frontendGet',
      template: relationshipTemplate,
      version: '1.0'
    })
    : {};
  const allRelationshipsList = formattedData.relationshipList ?? [];
  const allGatewayInfoList = formattedData.gatewayInfoList ?? [];
  await store.dispatch({
    type: 'DATA_UPDATE',
    relationshipData: {
      allGatewayInfoList,
      allRelationships: allRelationshipsList
    }
  });
  return {
    ...apiRes,
    data: !isEmpty(apiRes?.data) ? allRelationshipsList : []
  };
};

export const getRelationshipListByCode = (list) => {
  if (isEmpty(list)) { return []; }
  const newList = list.map((item) => {
    const titleWithCodePrefix = item.relationshipCode ? `${item.relationshipCode} - ` : '';
    const codeNumber = !isEmpty(item.relationshipCode) ? parseInt(item.relationshipCode, 10) : '';
    const itemTitle = item?.relationshipName || item?.title || '';
    const titleWithCode = itemTitle.startsWith(`${codeNumber} -`) ? itemTitle : `${titleWithCodePrefix}${itemTitle}`;
    return {
      ...item,
      codeNumber,
      title: titleWithCode
    };
  });
  return sortData(newList, 'codeNumber');
};

const getRelationshipForest = options => axiosRequest({
  fullPageLoad: false,
  ...options,
  url: crmEndpoint.relationship.forest,
  method: 'get'
});

export const setRelationshipForest = async (options) => {
  const apiRes = await getRelationshipForest(options);
  const hasData = !isEmpty(apiRes?.data);
  const formattedData = hasData
    ? transformData({
      data: apiRes?.data,
      toSchema: 'frontend',
      template: RelationshipForestTemplate
    })
    : {};
  hasData && await handleAddToLocalDB('relationshipForest', formattedData);
  await store.dispatch({
    type: 'DATA_UPDATE',
    relationshipData: {
      // `relationshipForest` data now stored in local db, but dispatch still needed
      // to handle timestamp changes (ie, refreshing data)
      relationshipForestTimestamp: Date.now(),
      relationshipForest: {}
    }
  });
  return {
    ...apiRes,
    data: hasData ? formattedData : null
  };
};

export const getLocalDbRelationshipForest = async (options) => {
  const {
    forceNewRequest, // Forces a new GET request
    newRequestIfEmpty // Makes GET request if local DB is empty
  } = options || {};
  let relationshipForestData = {};
  const axiosOptions = { fullPageLoad: false, ...options };
  if (forceNewRequest) {
    const apiRes = await setRelationshipForest(axiosOptions);
    relationshipForestData = apiRes?.data || {};
  } else {
    const localDbData = await handleDecompressData('relationshipForest');
    if (newRequestIfEmpty && isEmpty(localDbData)) {
      const apiRes = await setRelationshipForest(axiosOptions);
      relationshipForestData = apiRes?.data || {};
    } else {
      relationshipForestData = localDbData || {};
    }
  }
  return relationshipForestData;
};

export const getRelationshipDetail = async (guid, options) => {
  // DEPRECATED - DO NOT USE THIS METHOD AS A REFERENCE WHEN BUILDING OUT AXIOS REQUESTS
  const {
    useFullPageLoader: fullPageLoad = true
  } = options || {};
  if (!isEmpty(guid)) {
    const apiRes = await axiosRequest({
      fullPageLoad,
      url: `${crmEndpoint.relationship.v2Detail}`,
      method: 'get',
      tokenRequired: true,
      requestGuid: { relationshipId: guid }
    });
    if (apiRes?.errorDetails instanceof Error) {
      return apiRes.errorDetails;
    }
    if (!isEmpty(apiRes?.data)) {
      // DEPRECATED SERIALIZE, should use global transformData() method.
      const {
        relationshipId,
        relationshipCode,
        relationshipName,
        parentRelationshipId,
        childPartnerId,
        agentChain,
        bankName,
        processorName,
        processorFrontEnd,
        paymentGateway,
        riskProfile
      } = apiRes.data.relationship;
      const relationshipDetails = {
        relationshipId: relationshipId || guid,
        relationshipCode,
        relationshipName,
        parentRelationshipId,
        childPartnerId,
        agentChain,
        bankName,
        processorName,
        processorFrontEnd,
        paymentGateway,
        riskProfile
      };
      return relationshipDetails;
    }
  }
  return {};
};

export const getPartnerDetail = (partnerId, axiosOptions) => (
  axiosRequest({
    url: `${crmEndpoint.partner.v2Detail}`,
    method: 'get',
    requestGuid: { partnerId },
    fullPageLoad: false,
    ...axiosOptions
  })
);

export const getMerchants = async (options) => {
  const {
    fullPageLoad = true
  } = options || {};
  const apiRes = await axiosRequest({
    fullPageLoad,
    url: `${crmEndpoint.merchant.root}`,
    method: 'get',
    tokenRequired: true
  });
  // DEPRECATED SERIALIZE, should use global transformData() method.
  const deserialize = data => createPartnerTree(data);
  const formatted = apiRes?.data ? deserialize(apiRes.data) : [];
  if (!isEmpty(formatted)) {
    await handleAddToLocalDB('nestedMerchants', formatted);
    const graphColorMap = getRelationshipAndMerchantColorMap(apiRes?.data || []);
    !isEmpty(graphColorMap) && await handleAddToLocalDB('guidColorMap', graphColorMap);
  }
  await store.dispatch({
    type: 'DATA_UPDATE',
    merchantData: {
      // `nestedMerchants` are now stored in local db, but dispatch still needed
      // to handle timestamp changes (ie, refreshing data)
      timestamp: Date.now(),
      nestedMerchants: []
    }
  });
  return {
    ...apiRes,
    data: !isEmpty(apiRes?.data) ? formatted : []
  };
};

export const getExternalUserMerchantList = (nestedMerchantList) => {
  // Flatten merchants list for external users
  const allMerchants = getAllMerchants(nestedMerchantList ?? []);
  return !isEmpty(allMerchants)
    ? sortData(allMerchants.reduce((acc, item) => {
      const exists = !isEmpty( // check if the guid is already included in the merchant list
        (acc || []).find(m => (!isEmpty(item.guid) && m.guid === item.guid))
      );
      return exists ? acc : acc.concat({
        title: item?.dba,
        value: item?.guid,
        type: item?.type,
        guid: item?.guid,
        dba: item?.dba,
        legalName: item?.legalName
      });
    }, []), 'title')
    : [];
};

export const getLocalDbNestedMerchants = async (options) => {
  const {
    forceNewRequest, // Forces a new GET /merchant request
    newRequestIfEmpty // Makes GET /merchant request if local DB is empty
  } = options || {};
  let nestedMerchants = [];
  const axiosOptions = { fullPageLoad: false, ...options };
  if (forceNewRequest) {
    const apiRes = await getMerchants(axiosOptions);
    nestedMerchants = apiRes?.data || [];
  } else {
    const localDbData = await handleDecompressData('nestedMerchants');
    if (newRequestIfEmpty && isEmpty(localDbData)) {
      const apiRes = await getMerchants(axiosOptions);
      nestedMerchants = apiRes?.data || [];
    } else {
      nestedMerchants = localDbData || [];
    }
  }
  return nestedMerchants;
};

export const getPartners = async (options) => {
  const {
    refreshAll,
    fullPageLoad = true
  } = options || {};
  const { dataUpdate } = store.getState();
  const { partnerData } = dataUpdate || {};
  const refresh = isEmpty(partnerData.allPartners) || refreshAll;
  refresh && await store.dispatch({
    type: 'DATA_UPDATE',
    partnerData: {
      getPartnersInProgress: true
    }
  });
  const apiRes = await axiosRequest({
    fullPageLoad,
    url: `${crmEndpoint.partner.root}`,
    method: 'get',
    tokenRequired: true
  });
  // DEPRECATED SERIALIZE, should use global transformData() method.
  const deserialize = data => data.filter(p => !isEmpty(p.partnerId))
    .map(p => ({
      value: p.partnerId,
      title: p.partnerName || 'Unknown Partner Name',
      partnerBusinessCode: p.partnerCode
    }));
  const formatted = apiRes?.data ? sortData(deserialize(apiRes.data), 'title') : [];
  await store.dispatch({
    type: 'DATA_UPDATE',
    partnerData: {
      getPartnersInProgress: false,
      allPartners: formatted
    }
  });
  return {
    ...apiRes,
    data: !isEmpty(apiRes?.data) ? formatted : []
  };
};

export const getRiskCategories = async (options) => {
  const {
    fullPageLoad = true
  } = options || {};
  const apiRes = await axiosRequest({
    fullPageLoad,
    method: 'get',
    url: crmEndpoint.risk.riskCategory
  });
  const formattedCategories = apiRes?.errorDetails instanceof Error
    ? []
    : transformData({
      data: apiRes?.data || {},
      toSchema: 'get',
      template: riskCategoryTemplate,
      version: '1.0'
    });
  isEmpty(apiRes?.errorDetails) && await store.dispatch({
    type: 'DATA_UPDATE',
    riskData: {
      allRiskCategories: formattedCategories
    }
  });
  return {
    ...apiRes,
    ...(!isEmpty(apiRes?.data) && { data: formattedCategories || [] })
  };
};

export const refreshAllData = async (options = {}) => {
  const {
    callback,
    fullPageLoad = true,
    showSuccessAlert = true,
    refreshAll = true
  } = options;
  const { authenticate, dataUpdate } = store.getState();
  const {
    partnerData,
    relationshipData,
    riskData
  } = dataUpdate || {};
  const { user } = authenticate || {};
  const { isInternal = false } = user;
  if (fullPageLoad) {
    await fullPageLoader(true);
  }
  // if data IS NOT in the store
  // OR data IS in the store, but we want to refresh all -> get data
  const refreshRisk = window.location.pathname.includes('risk') && (isEmpty(riskData?.allRiskCategories) || refreshAll);
  const localDbMerchantList = await getLocalDbNestedMerchants(options);
  const nestedMerchantsEmpty = isEmpty(localDbMerchantList);
  const localDbRelationshipForest = await getLocalDbRelationshipForest();
  const relationshipForestEmpty = isEmpty(localDbRelationshipForest);
  const requests = [
    ...(refreshRisk ? [() => getRiskCategories(options)] : []),
    ...(nestedMerchantsEmpty || refreshAll
      ? [() => getMerchants(options)] : []),
    ...((isEmpty(partnerData?.allPartners) || refreshAll) && isInternal
      ? [() => getPartners(options)] : []),
    ...((isEmpty(partnerData?.relationshipMap) || refreshAll) && isInternal
      ? [() => getRelationshipMapping(options)] : []),
    ...((isEmpty(relationshipData?.allRelationships) || refreshAll) && isInternal
      ? [() => getRelationships(options)] : []),
    ...((relationshipForestEmpty || refreshAll) && isInternal
      ? [() => setRelationshipForest(options)] : [])
  ];
  return Promise.allSettled(requests.map(req => req()))
    .then(async () => {
      if (showSuccessAlert) {
        await store.dispatch({
          type: 'ALERT_BAR',
          barStyle: 'success',
          message: refreshRisk ? 'Success! Your Risk Categories and Alerts have been refreshed.' : `Success! Your Merchant${isInternal ? ', Partner, and Relationship' : ''} data has been refreshed.`
        });
      }
      if (fullPageLoad) {
        await fullPageLoader(false);
      }
      callback && callback();
      return true;
    });
};

export const getRelationshipMapping = async (options) => {
  const {
    fullPageLoad = true,
    errorOptions = {},
    partnerId = ''
  } = options || {};
  const apiRes = await axiosRequest({
    fullPageLoad,
    url: `${crmEndpoint.partner.relationshipMapping}`,
    method: 'get',
    tokenRequired: true,
    errorOptions,
    requestGuid: !isEmpty(partnerId) ? { partnerId } : {}
  });
  // DEPRECATED SERIALIZE, should use global transformData() method.
  const deserialize = data => data.map(r => ({
    title: r.relationshipName,
    value: r.relationshipId,
    relationshipCode: r.relationshipCode,
    partnerId: r.partnerId,
    partnerName: r.partnerName
  }));
  const formatted = apiRes?.data?.mappingData ? sortData(deserialize(apiRes.data.mappingData), 'title') : [];
  await store.dispatch({
    type: 'DATA_UPDATE',
    partnerData: {
      relationshipMap: formatted
    }
  });
  return {
    ...apiRes,
    data: !isEmpty(apiRes?.data) ? formatted : []
  };
};

export const getActiveId = (key, callback) => {
  const { guidUpdate, dataUpdate } = store.getState();
  const {
    partnerData,
    relationshipData
  } = dataUpdate || {};
  const { allPartners } = partnerData || {};
  const { allRelationships } = relationshipData || {};
  let response;
  const paramId = getParams().id || null;
  let paramIdKeyMatch;
  // verify that the paramId being passed in the URL is of the type being requested.
  if (paramId && key === 'activePartnerId') {
    const match = (allPartners || []).find(p => p.value === paramId);
    if (match) { // paramId is a partner, and the guid exists
      paramIdKeyMatch = { guid: match.value, dba: match.title };
    }
  }
  if (paramId && key === 'activeRelationshipIdList') {
    const match = (allRelationships || []).find(r => r.value === paramId);
    if (match) { // paramId is a relationship, and the guid exists
      paramIdKeyMatch = [{ guid: match.value, dba: match.title }];
    }
  }
  if (paramIdKeyMatch) {
    response = paramIdKeyMatch;
    callback && callback(response);
  } else {
    switch (key) {
      case 'activeRelationshipIdList':
        response = guidUpdate.relationshipIdList || [];
        break;
      case 'activePartnerId':
        response = guidUpdate.partnerId;
        break;
      default:
        response = { guid: '', dba: '' };
    }
  }
  return response;
};

export const getEmployeeName = (first, last) => {
  const name = `${first || ''} ${last || ''}`;
  return isEmpty(name) ? 'N/A' : name;
};

export const getDepartmentNames = (employeeGroups) => {
  if (isEmpty(employeeGroups)) return [];
  const groups = employeeGroups.map(group => snakeToTitle(group.employeeGroupName));
  return [...groups].sort().join(', ');
};

export const getTickets = async (options) => {
  const {
    requestGuid = {},
    fullPageLoad = true,
    customQueryParams = [],
    firstPage = false, // REQUIRED - must use multiCall/firstPage
    multiCall = true // REQUIRED - must use multiCall/firstPage
  } = options || {};
  const apiRes = await crmGetAllPageData(
    {
      axiosRequestOptions: {
        url: `${crmEndpoint.ticket.root}`,
        fullPageLoad,
        requestGuid,
        ...options
      },
      customQueryParams,
      dataKey: 'tickets',
      firstPage,
      multiCall
    }
  );
  return {
    errorDetails: apiRes.errorDetails ?? null,
    state: apiRes.state,
    data: apiRes.data
  };
};

export const getMyTickets = async (email) => {
  const employeeList = await getEmployees();
  const filteredEmployees = employeeList?.data?.filter(emp => emp.email === email);
  const employeeId = filteredEmployees?.[0]?.employeeId;
  // SNEK-2329
  // Only request one ticket row and instead use the returned pagingInfo.totalNumberOfRecords
  // to get the count of open tickets. For Risk Users with 1000s of tickets, this is much cheaper
  const tickets = await axiosRequest({
    fullPageLoad: false,
    url: `${crmEndpoint.ticket.root}`,
    method: 'get',
    tokenRequired: true,
    config: {
      params: {
        assignedEmployeeId: employeeId,
        ticketStatus: 'open',
        pageIndex: 0,
        pageSize: 1
      }
    }
  });
  const openTickets = tickets?.data?.pagingInfo?.totalNumberOfRecords || 0;
  return {
    state: tickets.state,
    data: openTickets
  };
};

export const getAllCrabApplications = async (options) => {
  // Makes all api calls to get crab apps, returns the final merged data
  const {
    requestGuid,
    prevApiRes,
    multiCall = true, // REQUIRED - must use multiCall/firstPage
    firstPage = true, // REQUIRED - must use multiCall/firstPage
    firstPageData,
    customQueryParams,
    fullPageLoad = false
  } = options || {};
  const responseDataKey = 'applications';
  const apiRes = await crmGetAllPageData({
    dataKey: responseDataKey,
    firstPage,
    multiCall,
    customQueryParams,
    axiosRequestOptions: {
      fullPageLoad,
      url: `${endpoint.crab.v1.application.root}`,
      method: 'get',
      requestGuid
    }
  });
  const { pagingInfo = {}, [responseDataKey]: currentPageData } = apiRes?.data || {};
  const allData = [
    ...(firstPageData ?? []),
    ...(currentPageData ?? [])
  ];
  if (firstPage) {
    const hasMoreRecords = !isEmpty(pagingInfo) &&
      pagingInfo?.totalNumberOfRecords > pagingInfo?.pageSize;
    if (hasMoreRecords) {
      return getAllCrabApplications({
        ...options,
        prevApiRes: apiRes,
        firstPageData: currentPageData || [],
        firstPage: false
      });
    }
  }
  return {
    data: allData || [],
    errorDetails: apiRes?.errorDetails instanceof Error
      ? apiRes?.errorDetails
      : prevApiRes?.errorDetails || null,
    state: apiRes?.state || {}
  };
};

const getAllPrevets = async (options) => {
  const {
    multiCall = true, // REQUIRED - must use multiCall/firstPage
    firstPage = true, // REQUIRED - must use multiCall/firstPage
    firstPageData,
    customQueryParams,
    fullPageLoad = false
  } = options || {};
  const responseDataKey = 'prevets';
  const apiRes = await crmGetAllPageData({
    dataKey: responseDataKey,
    firstPage,
    multiCall,
    customQueryParams,
    axiosRequestOptions: {
      fullPageLoad,
      url: `${endpoint.crab.v1.prevet.root}`,
      method: 'get',
      requestGuid: {}
    }
  });
  const { pagingInfo = {}, [responseDataKey]: currentPageData } = apiRes?.data || {};
  const allData = [
    ...(firstPageData ?? []),
    ...(currentPageData ?? [])
  ];
  if (firstPage) {
    const hasMoreRecords = !isEmpty(pagingInfo) &&
      pagingInfo?.totalNumberOfRecords > pagingInfo?.pageSize;
    if (hasMoreRecords) {
      return getAllPrevets({
        ...options,
        firstPageData: currentPageData || [],
        firstPage: false
      });
    }
  }
  return allData;
};

export const getMyPrevets = async (email, transformOptions) => {
  const requestOptions = {
    firstPage: true,
    multiCall: true,
    customQueryParams: [
      { key: 'appCompletionStatus', value: 'uncompleted_only' }
    ]
  };
  const allPrevets = await getAllPrevets(requestOptions);
  const formattedCount = await transformData({
    data: {
      prevets: allPrevets ?? [],
      options: transformOptions,
      email
    },
    template: prevetTemplate,
    version: '1.0',
    toSchema: 'notifications'
  });
  return formattedCount;
};

export const fullPageLoader = bool => store.dispatch({
  type: 'TOGGLE_LOADER',
  loading: bool
});

export const isTokenValid = () => sharedIsTokenValid(store);

export const handleSignOutMeco = options => (
  handleSignOut({
    ...options,
    axiosRequest,
    // TODO: BIRB-8124 - once BE creates endpoint for MECO, use here & mock in `stubDataMap`
    // signOutEndpoint: '',
    store,
    handleClearLocalDB
  })
);

export const axiosDefaultUtils = { // default utils for apiCall
  handleApiError,
  createCsrfHeader,
  isTokenValid
};

export const axiosRequest = async (options, requestBody = {}) => {
  const { url, fullPageLoad = true } = options;
  const utils = {
    ...axiosDefaultUtils,
    ...fullPageLoad && { fullPageLoader }
  };
  const {
    urlWithNoParams,
    queryStringParams = null
  } = getUrlObject(url);
  return apiCall({
    ...options,
    ...(useStubData && {
      stubData: await getMockData(options),
      url: urlWithNoParams, // in local dev, send url without params
      queryStringParams
    })
  }, utils, requestBody);
};

export const fetchRequest = async (options, requestBody) => {
  // This should only be used if axiosRequest cannot be used
  // (eg, loading/downloading files using s3 upload/download links)
  const {
    url,
    instanceMethod,
    fullPageLoad = true
  } = options || {};
  const utils = {
    ...axiosDefaultUtils,
    ...fullPageLoad && { fullPageLoader }
  };
  const mockUrlMap = useStubData
    ? { // fetch instanceMethod - one of [arrayBuffer, blob, clone, formData, json, text]
      blob: endpoint.crab.v1.file.dummyDownloadUrl,
      arrayBuffer: endpoint.crab.v1.file.dummyDownloadUrl
    }
    : {};
  const mockOptions = useStubData
    ? { ...options, url: mockUrlMap[instanceMethod || 'blob'] }
    : {};
  const {
    urlWithNoParams,
    queryStringParams = null
  } = getUrlObject(url);
  return fetchCall({
    ...options,
    ...(useStubData && {
      stubData: await getMockData(mockOptions),
      url: urlWithNoParams, // in local dev, send url without params
      queryStringParams,
      ...mockOptions
    })
  }, utils, requestBody);
};

export const crmGetAllPageData = async (options) => {
  // NOTE: look at the apiGetAllPageData method for required params
  // util for getting all paged data in the CRM
  const {
    axiosRequestOptions = {}
  } = options || {};
  const {
    fullPageLoad
  } = axiosRequestOptions || {};
  const utils = {
    ...axiosDefaultUtils,
    ...fullPageLoad && { fullPageLoader }
  };
  const stubDataOptions = {
    ...(envIsLocalOnly() && {
      stubData: await getMockData(axiosRequestOptions)
    })
  };
  const apiResponse = await apiGetAllPageData(
    options, utils, stubDataOptions
  );
  return apiResponse;
};

export const getInnerAlertBarState = (options) => {
  const alertOptions = { ...options, axiosRequest };
  return sharedGetInnerAlertBarState(alertOptions);
};

export const getEmployeesFromEndpoint = async (options) => {
  const { includeDisabledEmployees = true } = options || {};
  const apiRes = await axiosRequest({
    fullPageLoad: false,
    url: `${crmEndpoint.employee.root}`,
    method: 'get',
    tokenRequired: true,
    config: {
      params: {
        includeDisabledEmployees
      }
    }
  });
  let formatted;
  if (!isEmpty(apiRes?.data)) {
    formatted = await transformData({
      data: apiRes.data,
      toSchema: 'frontend',
      template: employeeTemplate,
      version: '1.0'
    });
    sortData(formatted, 'firstName');
    return { formatted, apiRes };
  }
  return { formatted: [], apiRes };
};

export const getEmployees = async (options) => {
  const { dataUpdate, authenticate } = store.getState();
  const { user } = authenticate || {};
  const { isInternal } = user || {};
  const { employeeData } = dataUpdate || {};
  const { employees } = employeeData || [];
  // Skip the API call if the store is already populated
  // But if options are passed in, we are doing a refresh, so make the call
  if ((isEmpty(employees) || !isEmpty(options)) && isInternal) {
    const response = await getEmployeesFromEndpoint(options);
    const { formatted, apiRes } = response;
    await store.dispatch({
      type: 'DATA_UPDATE',
      employeeData: {
        employees: !isEmpty(formatted) ? formatted : apiRes.data
      }
    });
    return {
      ...(apiRes?.errorDetails && { errorDetails: apiRes.errorDetails }),
      state: apiRes.state,
      data: !isEmpty(formatted) ? formatted : apiRes.data
    };
  }
  // Return the employee data from the store if we already have it
  return new Promise((resolve, reject) => {
    resolve({ data: employees || [] });
  });
};

export const getResolvedEmployeeList = async (options) => {
  const { dataUpdate } = store.getState();
  const { employeeData } = dataUpdate || {};
  const { employees } = employeeData || [];
  const resolvedEmployees = !isEmpty(employees) ? employees : await getEmployees(options);
  return resolvedEmployees?.data || resolvedEmployees;
};

export const getEmployeeDetails = async (requestGuid) => {
  const apiRes = await axiosRequest({
    fullPageLoad: true,
    url: `${crmEndpoint.employee.detail}`,
    requestGuid,
    method: 'get',
    tokenRequired: true
  });
  return {
    state: apiRes.state,
    data: apiRes.data,
    errorDetails: apiRes.errorDetails
  };
};

export const getEmployeeGroups = async (options) => {
  const {
    fullPageLoad = false,
    excludeExternal = false
  } = options || {};
  const externalGroups = bankNameList.map(item => item?.title);
  const apiRes = await axiosRequest({
    fullPageLoad,
    url: `${crmEndpoint.employee.employeeGroup}`,
    method: 'get',
    tokenRequired: true
  });
  // DEPRECATED SERIALIZE, should use global transformData() method.
  const deserialize = data => sortData(data.map(group => ({
    value: group.employeeGroupId,
    title: snakeToTitle(group.employeeGroupName)
  })), 'title');
  const formatted = apiRes?.data ? deserialize(apiRes.data) : [];
  await store.dispatch({
    type: 'DATA_UPDATE',
    employeeData: {
      employeeGroups: formatted
    }
  });
  if (!isEmpty(formatted)) {
    if (excludeExternal) {
      return {
        ...(apiRes?.errorDetails instanceof Error && { errorDetails: apiRes?.errorDetails }),
        state: apiRes.state,
        data: formatted.filter(group => !externalGroups.includes(group.title))
      };
    }
    return {
      ...(apiRes?.errorDetails instanceof Error && { errorDetails: apiRes?.errorDetails }),
      state: apiRes.state,
      data: formatted
    };
  }
  return {
    ...(apiRes?.errorDetails instanceof Error && { errorDetails: apiRes?.errorDetails }),
    state: apiRes.state,
    data: apiRes.data
  };
};

export const employeeGroupsInclude = (allowedGroups = '' || []) => {
  // checks if employee's assigned groups are part of the allowedGroups
  const { authenticate } = store.getState();
  const { user } = authenticate;
  const { employeeGroupList = [] } = user;
  const mappedEmployeeGroups = employeeGroupList.map(g => ignoreCase(g));
  if (Array.isArray(allowedGroups)) {
    const formattedAllowedGroups = allowedGroups.map(g => ignoreCase(snakeToTitle(g)));
    return formattedAllowedGroups.some(g => mappedEmployeeGroups.includes(g));
  }
  if (typeof allowedGroups === 'string') {
    const allowedGroup = ignoreCase(allowedGroups);
    return mappedEmployeeGroups.includes(allowedGroup);
  }
  return false;
};

export const handleSetVarListPolling = (pollingOn, options) => {
  store.dispatch({
    type: 'DATA_UPDATE',
    varListPolling: { pollingOn: pollingOn ?? false }
  });
};

export const closeFormConfirm = (formInProgress, action) => (formInProgress
  ? swal({
    title: `Closing form, are you sure?`,
    text: 'It looks like you made some changes.',
    buttons: ['Keep editing', `Yes, cancel all changes`],
    className: 'swal-corvia-warning',
    dangerMode: true,
    icon: 'warning',
    closeOnClickOutside: false,
    closeOnEsc: false
  }).then(async (result) => {
    const canClose = !!result;
    return { sidebarOpen: !canClose, formInProgress: !canClose, action };
  }) : { sidebarOpen: false, formInProgress: false, action });

const toggleLoader = (loading = true) => (store.dispatch({ type: 'TOGGLE_LOADER', loading }));

const customSettingsUtils = () => ({ axiosRequest, toggleLoader });

const customSettingsOptions = options => ({
  ...options,
  structure: mecoSettingsStructure
});

export const getCustomSettings = async (options) => {
  const allOptions = customSettingsOptions(options);
  const utils = customSettingsUtils();
  const apiRes = await getCustomSettingsShared(allOptions, utils);
  return apiRes;
};

export const saveCustomSettings = async (options, requestBody) => {
  const allOptions = customSettingsOptions(options);
  const utils = customSettingsUtils();
  const apiRes = await saveCustomSettingsShared(allOptions, utils, requestBody);
  return apiRes;
};
