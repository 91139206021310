import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { mockRiskExposureInputs } from '@f1/shared/src/data/crab/mockCrabV1ApplicationMpaDetail';
import { createMockFile } from '@f1/shared/src/data/sharedBoarding/mockBoardingApplicationFiles';
import { sortData } from '@f1/shared/src/_helpers';
import { createMockGiactRes } from '../highRiskBoardingTool/thirdPartyServices/v1/giact/mockV1GiactPost';
import { getMockEmployee } from '../_mockHelpers';

// V4 has same result as V1 & V3, but only returns them from the new GET call.
import { createMockPrincipals } from '../highRiskBoardingTool/thirdPartyServices/v1/match/mockMatchGet';
import { generatePossibleMatch, createMockMatchResults } from '../highRiskBoardingTool/thirdPartyServices/v1/match/mockMatchPost';
import { createRelatedApplications, createRelatedMerchants } from '../crab/mockEmployeeCrabV1ApplicationTask';

const getMockMpaId = index => (`${index < 11 ? '0' : (index < 21 && '1') || (index <= 30 && '2') || '0'}`);

const createMockBusinessOwner = () => {
  const mockUser = getMockValue({ type: 'user' });
  const mockAddress = getMockValue({ type: 'address' });
  return {
    firstName: mockUser.firstName,
    middleName: 'string',
    lastName: mockUser.lastName,
    generationCode: mockUser.generation,
    ssn: getMockValue({ type: 'ssn' }),
    dateOfBirth: {
      day: getMockValue({ min: 1, max: 28 }),
      month: getMockValue({ min: 1, max: 12 }),
      year: getMockValue({ min: 1960, max: 2000 })
    },
    title: mockUser.jobTitle,
    address: {
      street: mockAddress.fullAddress,
      city: mockAddress.cityName,
      state: mockAddress.stateCode,
      zip: mockAddress.zipCode
    },
    driverLicense: {
      state: mockAddress.stateCode,
      number: getMockValue({ min: 1000, max: 2000 })
    }
  };
};
const createMockMatchRequest = merchant => ({
  // same as V1, but no callMatch property
  acquirerId: getMockValue({ min: 10, max: 100, stringify: true }),
  merchant
});
const createMockMatchGetData = (guid, options) => {
  const mockSearchAll = getMockValue({ type: 'list', list: ['N', 'Y'] });
  const mockMerchantInput = {
    urlGroup: Array.from({ length: getMockValue({ min: 1, max: 3 }) }).map(() => ({
      exactMatchUrls: ['https://breakingTheLaw.com'],
      closeMatchUrls: ['https://breakingTheLaw2.com'],
      noMatchUrls: [getMockValue({ type: 'url' })]
    })),
    searchCriteria: {
      searchAll: mockSearchAll,
      region: mockSearchAll === 'N' ? undefined : ['NA'],
      country: mockSearchAll === 'N' ? undefined : ['US'],
      minPossibleMatchCount: getMockValue()
    },
    principal: createMockPrincipals({ length: 3 })
  };
  const mockMerchantResult = generatePossibleMatch(mockMerchantInput);
  const mockMatchResults = createMockMatchResults(mockMerchantResult, options);
  const mockMatchData = {
    applicationMpaId: guid,
    matchRequest: createMockMatchRequest(mockMerchantResult),
    matchResults: mockMatchResults
  };
  return mockMatchData;
};

const getMockGiactRequest = () => {
  const mockAddressBusiness = getMockValue({ type: 'address' });
  const mockAddressPersonal = getMockValue({ type: 'address' });
  const mockPerson = getMockValue({ type: 'user' });
  const mockRequest = {
    giactBusinessCustomerRequest: {
      businessName: `Mock DBA Business Name 1: ${getMockValue({ type: 'description' })}`,
      ein: getMockValue({ type: 'ssn' }),
      businessAddress: mockAddressBusiness.fullAddress,
      businessCity: mockAddressBusiness.cityName,
      businessState: mockAddressBusiness.stateCode,
      businessZip: mockAddressBusiness.zipCode,
      routingNumber: '123456789',
      bankAccountNumber: '11122233344455'
    },
    giactPersonalCustomerRequest: {
      firstName: mockPerson.firstName,
      lastName: mockPerson.lastName,
      streetAddress: mockAddressPersonal.fullAddress,
      city: mockAddressPersonal.cityName,
      state: mockAddressPersonal.stateCode,
      zipCode: mockAddressPersonal.zipCode,
      // only diff with v3 is the addition of this ssn
      ssn: getMockValue({ type: 'ssn' })
    }
  };
  return mockRequest;
};
const approvedDecisions = ['approved'];
const declinedDecisions = ['declined'];
const referDecisions = ['refer_recommended_declined', 'refer'];
const getRuleOutput = (resultIndex, options) => Array.from({
  length: 30 // don't change, needed for tests
}).map((item, index) => {
  const { mockGdsDecision, useRandomDecision } = options || {};
  const isDecline = declinedDecisions.includes(mockGdsDecision);
  const isRefer = referDecisions.includes(mockGdsDecision);
  const isApproved = approvedDecisions.includes(mockGdsDecision);
  const mockDecision = (useRandomDecision && getMockValue({
    type: 'list',
    list: [...approvedDecisions, ...declinedDecisions, ...referDecisions]
  })) ||
  (isDecline && 'declined') ||
  (isRefer && getMockValue({ type: 'list', list: referDecisions })) ||
  (isApproved && 'approved') || null;
  const mockRuleCode = (mockDecision && (
    (index <= 10 && 'EXP-4444') || (index <= 20 && 'MAT-3333') ||
    (index <= 25 && 'GID-2222') || (index <= 30 && 'GAU-1111')
  )) || 'ERR-5555';
  return {
    gdsRuleResultId: `mock-gds-rule-result-id-${index}`,
    gdsRuleCode: mockRuleCode,
    description: getMockValue({ type: 'description' }),
    decision: mockDecision,
    appReviewTask: isApproved ? null : `what underwriting should do if this rule isn't approved${index > 10 ? '\n'.concat(getMockValue({ type: 'string' })) : ''}`,
    applicationMpaId: getMockMpaId(index),
    partyId: getMockValue({ type: 'ssn' }),
    partyType: getMockValue({ type: 'list', list: ['EIN', 'SSN'] }),
    bankAccountNumber: getMockValue({ type: 'accountNumber' }),
    reviewed: resultIndex > 0 ? getMockValue({ type: 'boolean' }) : isApproved
  };
});
const getRiskExposureOutputs = () => [
  'corvia', 'priority', 'repay' // riskFormulaType enums
].map(riskEnum => ({
  returnsRiskExposure: getMockValue({ type: 'number' }),
  chargebackRiskExposure: getMockValue({ type: 'number' }),
  deliveryRiskExposure: getMockValue({ type: 'number' }),
  feeRiskExposure: getMockValue({ type: 'number' }),
  grossRiskExposure: getMockValue({ type: 'number' }),
  riskFormulaType: riskEnum
}));
const getGiactResults = resultIndex => Array.from({ length: 3 }).map((item, mpaId) => {
  const mockResultListGiact = () => Array.from({
    length: getMockValue({ min: 2, max: 5 })
  }).map((listItem, listItemIndex) => {
    const mockIsoDate = getMockValue({ type: 'isoDate', ...(resultIndex === 0 && listItemIndex === 0 && { min: 2022, max: 2022 }) });
    const mockAddress = getMockValue({ type: 'address' });
    const mockBody = { businessState: mockAddress.stateCode };
    const { giactResponse: mockGiactResponse } = createMockGiactRes(mockBody, {
      dataFetchTimestamp: mockIsoDate
    });
    return {
      dataPullGroupId: mockIsoDate,
      giactRequest: getMockGiactRequest(),
      giactResponse: mockGiactResponse
    };
  });
  return {
    applicationMpaId: `${mpaId}`,
    resultList: resultIndex === 0 && mpaId === 2 ? [] : mockResultListGiact()
  };
});
const getMatchResults = resultIndex => Array.from({ length: 3 }).map((item, mpaId) => {
  const mockResultListMatch = () => Array.from({
    length: getMockValue({ min: 2, max: 5 })
  }).map(() => {
    const { matchRequest, matchResults } = createMockMatchGetData(mpaId, { resultIndex });
    return { matchRequest, matchResults };
  });
  return {
    applicationMpaId: `${mpaId}`,
    resultList: resultIndex === 0 && mpaId === 2 ? [] : mockResultListMatch()
  };
});
const experianResponse = resultIndex => ({
  experianRequestId: getMockValue({ type: 'guid' }),
  timestampQueried: getMockValue({ type: 'isoDate', ...(resultIndex === 0 && { min: 2022, max: 2022 }) }),
  employee: getMockEmployee({ includeManager: true }),
  experianResultPdf: createMockFile(1),
  experianResultJson: '{"requestId":"ec55cca0-9342-11e7-bddf-ebd4476c3102","responseMessage":"Your search did not match any records. Suggestions try adding optional fields like street address and phone to your search, check your spelling, and remember, the absence of a match can be valuable information.","comments":"my_guid_for_tracking","success":true,"results":[{"bin":"800914632","reliabilityCode":91.02,"businessName":"EXPERIAN","phone":"+17148307000","address":{"street":"535 ANTON BLVD","city":"Costa Mesa","state":"CA","zip":"92626","zipExtension":"9262"},"numberOfTradelines":3,"financialStatementIndicator":true,"keyFactsIndicator":true,"inquiryIndicator":true,"bankDataIndicator":true,"governmentDataIndicator":true,"executiveSummaryIndicator":true,"uccIndicator":true,"matchingNameAndAddress":{"businessName":"EXPERIAN","address":{"street":"535 ANTON BLVD","city":"Costa Mesa","state":"CA","zip":"92626","zipExtension":"9262"}},"businessGeocode":{"latitudeLongitudeLevel":"Roof Top Level","latitude":33.690764,"longitude":-117.873551,"msaCode":"5945","censusBlkGrpCode":"2","censusTractCode":"063907","cottageIndicator":true,"congressionalDistrictCode":"48","dateLastReported":"01/01/1977"}}]}'
});
const getExperianResults = resultIndex => Array.from({ length: 3 }).map((item, mpaId) => {
  const mockResultListExperian = () => Array.from({
    length: getMockValue({ min: 2, max: 5 })
  }).map(() => ({
    businessOwnerProfile: {
      experianRequest: {
        subcode: '0552027',
        businessOwners: Array.from({ length: getMockValue({ type: 'number', min: 1, max: 4 }) }).map(createMockBusinessOwner)
      },
      experianResponse: experianResponse(resultIndex)
    },
    businessSearch: {
      experianRequest: {
        subcode: '0552027',
        businessName: getMockValue({ type: 'businessName' }),
        city: getMockValue({ type: 'address' }).cityName,
        state: getMockValue({ type: 'address' }).stateCode,
        street: getMockValue({ type: 'address' }).fullAddress,
        zip: getMockValue({ type: 'address' }).zipCode,
        phone: getMockValue({ type: 'bePhone' }),
        geo: getMockValue({ type: 'boolean' })
      },
      experianResponse: experianResponse()
    }
  }));
  return {
    applicationMpaId: `${mpaId}`,
    resultList: resultIndex === 0 && mpaId === 2 ? [] : mockResultListExperian()
  };
});
const getRiskExposure = () => Array.from({
  length: 3
}).map((item, mpaIndex) => ({
  mpaApplication: {
    mpaApplicationId: `${mpaIndex}`,
    dbaName: `Mock DBA Name ${mpaIndex + 1}`
  },
  riskExposureInputs: mockRiskExposureInputs(),
  riskExposureOutputs: getRiskExposureOutputs()
}));
const gdsResultList = options => ((options && options.list) || [
  ...approvedDecisions,
  ...declinedDecisions,
  ...referDecisions,
  'no_rules_run', // FE-only value, to return "no_rules_run" styling in sub-cards
  'use_random' // FE-only value, to randomize data
]).map((mockDecision, index) => {
  const otherDecisionMap = {
    no_rules_run: '', // must be empty string
    use_random: 'refer'
  };
  const mockGdsDecision = otherDecisionMap[mockDecision] ?? mockDecision;
  return {
    gdsResultId: `mock-gds-result-id-${index}`, // don't change, needed for tests
    request: {
      applicationId: options.guid || getMockValue({ type: 'guid' }),
      callType: getMockValue({ type: 'list', list: ['CRE', 'RPC'] })
    },
    decisions: {
      decision: mockGdsDecision,
      decisionTimestamp: index === 0 // approved result should be most recent
        ? getMockValue({ type: 'isoDate', min: 2020, max: 2021 })
        : getMockValue({ type: 'isoDate', min: 2010 - index, max: 2010 - index }),
      ruleOutput: getRuleOutput(index, { mockGdsDecision, ...(`${mockDecision}`.includes('random') && { useRandomDecision: true }) })
    },
    giactResults: getGiactResults(index),
    experianResults: getExperianResults(index),
    matchResults: getMatchResults(index),
    riskExposure: getRiskExposure()
  };
});

// Post -> only returns the number of results expected
export const mockGdsApplicationV4Post = (body, params, options) => {
  const { guid } = options || {};
  const numberOfExpectedResults = mockGdsApplicationData(options).length;
  return {
    [guid]: {
      numberOfExpectedResults
    }
  };
};

const mockGdsApplicationData = (options) => {
  const startingList = gdsResultList(options)
    .map(item => ({ ...item, sortValue: item.decisions.decisionTimestamp }));
  const sortedData = sortData(startingList, 'sortValue', { direction: 'desc' });
  const mockGdsResultList = sortedData.map(item => Object.entries(item)
    .reduce((acc, [key, value]) => (key !== 'sortValue' ? ({ ...acc, [key]: value }) : acc), {}));
  return mockGdsResultList;
};

// Get -> returns all results.
// We keep polling this endpoint until the length matches the length returned from the post call.
export const mockGdsApplicationV4Get = (body, params, options) => {
  const { guid } = options || {};
  const mockGdsResultList = mockGdsApplicationData(options);
  return {
    [guid]: {
      gdsResultList: mockGdsResultList,
      relatedPersonData: {
        relatedApplications: createRelatedApplications(guid),
        relatedMerchants: createRelatedMerchants(guid)
      }
    }
  };
};
