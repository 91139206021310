import { endpoint } from '@f1/shared/src/_helpers';
import { sharedStubDataMap } from '@f1/shared/src/data/stubDataMap';
import { getMockDataFromMap } from '@f1/shared/src/_testHelpers';
import { mockDefaultPostRes } from '@f1/shared/src/data/_mockDataHelpers';
import { mockACHDetailsGet } from '@f1/shared/src/data/reports/achDetails';
import { mockAchFundingGet } from '@f1/shared/src/data/reports/achFunding';
import { mockTicketsExternalCommunicationGet, mockTicketsExternalCommunicationPutRes } from '@f1/shared/src/data/tickets/mockTicketsExternalCommunication';
import { mockBoardingApplicationTaskPendGet, mockBoardingApplicationTaskPendPut } from '@f1/shared/src/data/sharedBoarding/mockBoardingApplicationTaskPend';
import { mockCrabExternalCommunicationGet } from '@f1/shared/src/data/sharedBoarding/mockBoardingApplicationExternalCommunication';
import { mockCrabApplicationMpaDetailGet } from '@f1/shared/src/data/crab/mockCrabV1ApplicationMpaDetail';
import { mockBoardingApplicationDownloadUrlGet, mockBoardingApplicationFilesGet } from '@f1/shared/src/data/sharedBoarding/mockBoardingApplicationFiles';
import { mockFileV3Get } from '@f1/shared/src/data/file/mockFiles';
import { mockAchFundingSummeryGet } from '@f1/shared/src/data/reports/achFundingSummery';
import { mockBatchDetailsGet, mockTransactionReportTxnDetailsGet } from '@f1/shared/src/data/transactionReport/txnDetails';
import mockReportReserveDetailsGet, { mockReportReserveCurrentGet } from '@f1/shared/src/data/reports/mockReserve';
import { mockMerchantVarGet, mockVarPut } from '@f1/shared/src/data/common/merchantVar';
import mockCrabV1ToMpa from './file/mockCrabV1ToMpa';

import { filterEmployeeList } from './_templateHelpers';
import {
  crmEndpoint,
  thirdPartyServicesEndpoint,
  getBaseApiUrls,
  residualsEndpoint,
  infrastructureEndpoint
} from '../utilsEndpoints';

import {
  mockTicketsPut,
  mockTicketGet,
  returnTicketPostBody,
  returnApprovalRequestPutBody,
  returnBulkUpdateRequestPatchRes
} from './ticket/root';
import { mockTicketStatusOverview } from './ticket/statusOverview';
import { mockTicketHistoryRes } from './ticket/history';

import {
  mockEmployeeGetRes,
  mockEmployeeList,
  mockEmployeePostRes,
  mockEmployeePutRes
} from './employee/root';
import { mockEmployeeGroupGetRes } from './employee/employeeGroup';
import { mockEmployeeDetailGetRes } from './employee/detail';

import { mockMerchantGet } from './merchant/root';
import { mockMerchantV2DetailGet, mockStaticMapMerchantVar } from './merchant/v2/mockMerchantsV2';
import { mockMerchantV2Put } from './merchant/v2/mockPUTMerchants';
import { mockMpaFeesGet } from './merchant/v2/mockMpaFees';

import { mockPartnerGet } from './partner/root';
import { mockPartnerV2Put, mockPartnerV2DetailGet } from './partner/v2/mockPartnersV2Get';
import { mockPartnerBoardingGet } from './partner/boarding';
import { mockPartnerHealthGetRes } from './partner/health';
import { mockV1PartnerPut } from './partner/v2/root';
import { mockPartnerNotificationsGetRes } from './partner/notifications';

import { mockRelationshipGetRes } from './relationship/root';
import { mockRelationshipMappingGetWithGuid } from './partner/relationshipMapping';
import { mockRelationshipV2DetailGet } from './relationship/v2/mockRelationshipsV2Get';
import { mockRelationshipV2Put } from './relationship/v2/mockRelationshipsV2PutPost';
import { mockRelationshipForestGet } from './relationship/forest';
import { mockRelationshipBoardingGet } from './relationship/boarding';
import { mockRelationshipHealthGet, mockRelationshipHealthGetRes } from './relationship/health';
import { mockV1RelationshipPut } from './relationship/v2/root';
import { mockGetRelationshipListMerchant } from './relationship/listMerchant';

import { mockUserSignInPost } from './user/signIn';
import { mockCustomSettingsGet } from './infrastructure/customSettings';

import mockNotePut from './note/note';
import { mockNotesWithGroupsGet } from './note/mockNotesWithGroups';

import {
  mockFilesGetRes,
  mockFilesPutRes,
  mockFilesPathRes
} from './file/root';

// TransactionReport from CRM
import { mockTransactionReportAuthorizationGet } from './transactionReport/authorization';
import { mockTransactionReportChargebackGet } from './transactionReport/chargeback';
import { mockTransactionReportMiniGet } from './transactionReport/mini';
import { mockTransactionReportRefundGet } from './transactionReport/refund';
import { mockTransactionReportSalesGet } from './transactionReport/sales';
import { mockTransactionReportSummaryGet } from './transactionReport/summary';
import { mockTransactionReportRiskPartnerSpecialty } from './transactionReport/risk/partnerSpecialty';
import { mockMvbBinPerformance } from './transactionReport/risk/mockMvbBinPerformance';

import { mockUserDetailsGet } from './support/userDetails';
import mockAchDetailsPostRes from './support/achDetails';

import { mockAlerts } from './risk/landingPage/alerts/mockAlerts';
import { mockCategoryPutRes, mockRiskCategoriesGetRes } from './risk/landingPage/alerts/riskCategory/mockRiskCategories';
import { mockRiskSubcategoryGetRes, mockRiskSubcategoryPutRes } from './risk/landingPage/alerts/riskSubcategory/mockRiskSubcategories';
import { mockProcessingHistoryGetRes } from './risk/landingPage/history/processingHistory';
import { mockChargebackHistoryGetRes } from './risk/landingPage/history/chargebackHistory';
import { mockAllTimeHistoryV2GetRes } from './risk/landingPage/history/allTimeHistoryV2';
import { mockExposureGetRes } from './risk/landingPage/mockExposure';
import { mockClosingEntity } from './risk/rule/metric/closingEntity';
import { mockTriggerAndClosure } from './risk/rule/metric/triggerAndClosure';
import { mockRiskRuleGet } from './risk/rule/root';
import { mockReserveHistoryGetRes } from './risk/landingPage/history/reserveHistory';
import { mockAchRejectHistoryGetRes } from './risk/landingPage/history/achRejectHistory';
import { mockRiskCategoryMetricGet } from './risk/landingPage/alerts/riskCategory/metric/mockRiskCategoryMetrics';
import { mockRiskSubcategoryMetricGet } from './risk/landingPage/alerts/riskSubcategory/metric/mockRiskSubcategoryMetrics';
import { mockRiskProhibitedEntitiesGet, mockRiskProhibitedEntitiesPut } from './risk/prohibitedEntities';

import mockLookupBySecondaryFieldGetRes from './merchant/mockLookupBySecondaryField';

import { mockStickyRes } from './thirdPartyServices/mockSticky';

import mockResidualMidMappingPOSTRes from './residuals/mockMidMapping';
import { mockUploadFilesInputGet, mockUploadFilesOutputGet, mockUploadFilesPut } from './residuals/mockUploads';
import { mockV2TaskGetRes, mockV2TaskPutRes } from './residuals/task';
import { mockResidualsTaskDetailGet } from './residuals/taskDetail';
import { mockFileV2RepayList } from './file/v2/repay/mockFileV2RepayList';
import { mockV2RiskAssessmentGet, mockV2RiskAssessmentPut } from './merchant/v2/mockV2RiskAssessment';
import { mockRiskExposureGet } from './merchant/v2/mockRiskExposure';
import { getMockMerchantV2History } from './merchant/v2/mockV2History';

import { mockAvailableMidsGet, mockAvailableMidsPost } from './underwriting/mockAvailableMids';

import {
  defaultAppReviewEmployee,
  mockEmployeeCrabV1ApplicationGet,
  mockEmployeeCrabV1ApplicationDetailsGet
} from './crab/mockEmployeeCrabV1Application';
import {
  createMockTaskList,
  mockEmployeeCrabV1ApplicationTaskGet,
  mockEmployeeCrabV1ApplicationTaskMatchGet,
  mockEmployeeCrabV1ApplicationTaskProhibitedEntitiesGet,
  mockEmployeeCrabV1ApplicationTaskRelatedPersonsGet,
  mockEmployeeCrabV1ApplicationTaskWebsiteReviewGet,
  mockEmployeeCrabV1ApplicationTaskWhoisReviewGet,
  mockEmployeeCrabV1ApplicationTaskRiskExposureGet
} from './crab/mockEmployeeCrabV1ApplicationTask';
import { mockApplicationTaskConfigGet } from './crab/mockApplicationTaskConfig';
import { mockGdsApplicationV3 } from './thirdPartyServices/mockGdsApplicationV3';

import { mockGdsApplicationV4Post, mockGdsApplicationV4Get } from './thirdPartyServices/mockGdsApplicationV4';

import { mockMatchPostRes } from './highRiskBoardingTool/thirdPartyServices/v1/match/mockMatchPost';
import { mockMatchGetRes, mockV2MatchPost, mockMatchMerchantV2 } from './highRiskBoardingTool/thirdPartyServices/v1/match/mockMatchGet';
import { mockV1GiactGet, mockV2GiactPost, mockGiactMerchantV2 } from './highRiskBoardingTool/thirdPartyServices/v1/giact/mockV1GiactGet';
import { mockV3ExperianApplicationPost } from './highRiskBoardingTool/thirdPartyServices/experian/v3/mockV3Experian';
import { mockRubmapsApplicationV3 } from './thirdPartyServices/rubmaps/mockRubmapApplicationV3';
import { mockBinStatusGet } from './risk/binStatus/mockBinStatus';
import { mockApplicationApproveV3 } from './crab/mockCrabV1ApplicationApproval';
import { mockMerchantLedgerGet } from './merchant/merchantLedger';
import { mockNachaDetailsGet, mockNachaGet, mockNachaReturnsGet } from './nacha/mockNacha';
import { mockProfitabilityGet } from './profitability/mockProfitability';
import { mockEmployeePrevetsGet } from './crab/mockEmployeePrevetsGet';
import { mockExternalIntegrationGet } from './partner/mockExternalIntegration';
import { mockV2FeeGet } from './relationship/mockV2Fee';
import mockRepayTransactionHoldGet from './risk/repayTransactionHold';
import mockRepayTransactionHoldConfig from './risk/mockRepayTransactionHoldConfig';
import { mockRefundRequestGet } from './refundRequest/mockRefundRequest';

import { mockNegativeActionGet } from './crab/mockNegativeAction';
import { mockApplicationPendReport } from './crab/mockApplicationPendReport';

export const getMockData = options => getMockDataFromMap({ ...options, map: stubDataMap });

export const stubDataMap = {
  ...sharedStubDataMap,
  // USER
  [crmEndpoint.user.signIn]: {
    POST: mockUserSignInPost
  },
  [crmEndpoint.user.forgotPasswordRequest]: {
    POST: mockDefaultPostRes
  },
  [crmEndpoint.user.signUp]: {
    POST: mockDefaultPostRes
  },
  [crmEndpoint.user.forgotPasswordVerify]: {
    POST: mockDefaultPostRes
  },
  [crmEndpoint.user.sendPhoneVerification]: {
    POST: mockDefaultPostRes
  },
  [crmEndpoint.user.verifyPhone]: {
    POST: mockDefaultPostRes
  },
  [crmEndpoint.user.verifyEmail]: {
    POST: mockDefaultPostRes
  },
  [crmEndpoint.user.sendEmailVerification]: {
    POST: mockDefaultPostRes
  },
  // NOTE
  [crmEndpoint.note]: {
    GET: mockNotesWithGroupsGet,
    PUT: (body, params, options) => mockNotePut(body, params, options)
  },
  // MERCHANTS
  [crmEndpoint.merchant.root]: {
    GET: mockMerchantGet
  },
  [crmEndpoint.merchant.mpa]: {
    POST: mockDefaultPostRes
  },
  [crmEndpoint.merchant.v2Detail]: {
    GET: mockMerchantV2DetailGet
  },
  [crmEndpoint.merchant.v2]: {
    POST: mockDefaultPostRes,
    PUT: mockMerchantV2Put
  },
  [crmEndpoint.merchant.lookupBySecondaryField]: {
    GET: mockLookupBySecondaryFieldGetRes
  },
  [crmEndpoint.merchant.v2Closure]: {
    POST: mockDefaultPostRes
  },
  [crmEndpoint.merchant.v2bulkEmail]: {
    // returns a 204 so doesn't need a unique mock value
    POST: mockDefaultPostRes
  },
  [crmEndpoint.merchant.v2AchDetails]: {
    GET: (body, params, options) => mockACHDetailsGet(body, params, options),
    POST: (body, params, options) => mockAchDetailsPostRes(body, params, options)
  },
  [crmEndpoint.merchant.achFunding]: {
    GET: mockAchFundingGet
  },
  [crmEndpoint.merchant.achFundingSummery]: {
    GET: mockAchFundingSummeryGet
  },
  [crmEndpoint.merchant.v2Fee]: {
    GET: mockMpaFeesGet,
    POST: mockDefaultPostRes
  },
  [crmEndpoint.merchant.v2RiskAssessment]: {
    GET: (body, params, options) => mockV2RiskAssessmentGet(body, params, options),
    PUT: (body, params, options) => mockV2RiskAssessmentPut(body, params, options)
  },
  [crmEndpoint.merchant.riskExposure]: {
    GET: (body, params, options) => mockRiskExposureGet(body, params, options)
  },
  [crmEndpoint.merchant.v2History]: {
    GET: getMockMerchantV2History
  },
  [crmEndpoint.merchant.ledger]: {
    GET: (body, params, options) => mockMerchantLedgerGet(body, params, options)
  },
  [crmEndpoint.merchant.profitability]: {
    GET: (body, params, options) => mockProfitabilityGet(body, params, options),
    PUT: mockDefaultPostRes
  },
  [crmEndpoint.merchant.addTsysTerminal]: {
    POST: mockDefaultPostRes
  },
  [crmEndpoint.merchant.boardToGateway]: {
    POST: mockDefaultPostRes
  },
  [crmEndpoint.merchant.boardToProcessor]: {
    POST: mockDefaultPostRes
  },
  // PARTNERS
  [crmEndpoint.partner.root]: {
    GET: mockPartnerGet
  },
  [crmEndpoint.partner.relationshipMapping]: {
    GET: (body, params, options) => mockRelationshipMappingGetWithGuid(body, params, options)
  },
  [crmEndpoint.partner.boarding]: {
    GET: mockPartnerBoardingGet
  },
  [crmEndpoint.partner.health]: {
    GET: (body, params, options) => mockPartnerHealthGetRes(body, params, options)
  },
  [crmEndpoint.partner.v1]: {
    PUT: mockV1PartnerPut
  },
  [crmEndpoint.partner.v2Detail]: {
    GET: mockPartnerV2DetailGet
  },
  [crmEndpoint.partner.v2]: {
    POST: mockDefaultPostRes,
    PUT: mockPartnerV2Put
  },
  [crmEndpoint.partner.v2Health]: {
    GET: (body, params, options) => mockPartnerHealthGetRes(body, params, options)
  },
  [crmEndpoint.partner.notifications]: {
    GET: (body, params, options) => mockPartnerNotificationsGetRes(body, params, options)
  },
  [crmEndpoint.partner.externalIntegration]: {
    GET: (body, params, options) => mockExternalIntegrationGet(body, params, options),
    POST: mockDefaultPostRes
  },
  // RELATIONSHIPS
  [crmEndpoint.relationship.root]: {
    GET: mockRelationshipGetRes
  },
  [crmEndpoint.relationship.forest]: {
    GET: mockRelationshipForestGet
  },
  [crmEndpoint.relationship.boarding]: {
    GET: mockRelationshipBoardingGet
  },
  [crmEndpoint.relationship.health]: {
    GET: mockRelationshipHealthGet
  },
  [crmEndpoint.relationship.v1]: {
    PUT: mockV1RelationshipPut
  },
  [crmEndpoint.relationship.v2Detail]: {
    GET: (body, params, options) => mockRelationshipV2DetailGet(body, params, options)
  },
  [crmEndpoint.relationship.v2]: {
    POST: mockDefaultPostRes,
    PUT: (body, params, options) => mockRelationshipV2Put(body, params, options)
  },
  [crmEndpoint.relationship.v2Health]: {
    GET: (body, params, options) => mockRelationshipHealthGetRes(body, params, options)
  },
  [crmEndpoint.relationship.v2Fee]: {
    POST: mockDefaultPostRes,
    GET: (body, params, options) => mockV2FeeGet(body, params, options)
  },
  [crmEndpoint.relationship.listMerchant]: {
    GET: mockGetRelationshipListMerchant
  },
  // EMPLOYEES
  [crmEndpoint.employee.root]: {
    GET: mockEmployeeGetRes,
    DELETE: mockEmployeePostRes,
    PUT: mockEmployeePutRes,
    POST: mockEmployeePostRes
  },
  [crmEndpoint.employee.employeeGroup]: {
    GET: mockEmployeeGroupGetRes
  },
  [crmEndpoint.employee.detail]: {
    GET: mockEmployeeDetailGetRes
  },
  [crmEndpoint.employee.employeeGroupMember]: {
    PUT: mockDefaultPostRes
  },
  // TICKETS
  [crmEndpoint.ticket.root]: {
    GET: mockTicketGet,
    PUT: mockTicketsPut,
    POST: (body, params, options) => returnTicketPostBody(body, params, options)
  },
  [crmEndpoint.ticket.overview]: {
    GET: mockTicketStatusOverview
  },
  [crmEndpoint.ticket.history]: {
    GET: mockTicketHistoryRes
  },
  [crmEndpoint.ticket.approvalRequest]: {
    PUT: (body, params, options) => returnApprovalRequestPutBody(body, params, options)
  },
  [crmEndpoint.ticket.bulk]: {
    PATCH: (body, params, options) => returnBulkUpdateRequestPatchRes(body, params, options)
  },
  [crmEndpoint.ticket.externalCommunication]: {
    GET: (body, params, options) => mockTicketsExternalCommunicationGet(body, params, options),
    PUT: (body, params, options) => mockTicketsExternalCommunicationPutRes(body, params, options)
  },
  // FILE
  [crmEndpoint.file.root]: {
    PUT: mockFilesPutRes
  },
  [endpoint.file.v3.root]: {
    ...sharedStubDataMap[endpoint.file.v3.root],
    GET: (body, params, options) => mockFileV3Get(body, params, {
      ...options,
      mockDataMap: mockFilesGetRes,
      userType: 'employee'
    })
  },
  [endpoint.file.v3.tags]: {
    POST: mockDefaultPostRes
  },
  [crmEndpoint.file.v2Path]: {
    GET: mockFilesPathRes
  },
  [crmEndpoint.file.v2DummyUploadUrl]: {
    PUT: {}
  },
  [crmEndpoint.file.v2RepayList]: {
    // in dev, these dates have data: 2020-08-02, 2020-08-03, 2020-08-04
    GET: (body, params, options) => mockFileV2RepayList(body, params, options)
  },
  // THIRD PARTY SERVICES
  [thirdPartyServicesEndpoint.giactUpdate]: {
    POST: (body, params, options) => mockV1GiactGet(body, params, options)
  },
  [thirdPartyServicesEndpoint.giactUpdateV2]: {
    POST: (body, params, options) => mockV2GiactPost(body, params, options)
  },
  [thirdPartyServicesEndpoint.gdsApplicationV3]: {
    POST: mockGdsApplicationV3
  },
  [thirdPartyServicesEndpoint.gdsApplicationV4]: {
    POST: (body, params, options) => mockGdsApplicationV4Post(body, params, options),
    GET: mockGdsApplicationV4Get
  },
  [thirdPartyServicesEndpoint.giactMerchantV2]: {
    POST: mockGiactMerchantV2
  },
  [thirdPartyServicesEndpoint.rubmapsApplicationV3]: {
    POST: mockRubmapsApplicationV3
  },
  [thirdPartyServicesEndpoint.sticky]: {
    POST: mockStickyRes
  },
  [thirdPartyServicesEndpoint.experianApplicationV3]: {
    POST: mockV3ExperianApplicationPost
  },
  [thirdPartyServicesEndpoint.experianMerchantV3]: {
    POST: mockV3ExperianApplicationPost
  },
  [thirdPartyServicesEndpoint.match]: {
    POST: (body, params, options) => mockMatchPostRes(body, params, options)
  },
  [thirdPartyServicesEndpoint.matchUpdate]: {
    POST: (body, params, options) => mockMatchGetRes(body, params, options)
  },
  [thirdPartyServicesEndpoint.matchUpdateV2]: {
    POST: (body, params, options) => mockV2MatchPost(body, params, options)
  },
  [thirdPartyServicesEndpoint.matchMerchantV2]: {
    POST: mockMatchMerchantV2
  },
  // SUPPORT
  [crmEndpoint.support.userDetails]: {
    GET: (body, params) => mockUserDetailsGet(body, params)
  },
  [crmEndpoint.support.resetUser]: {
    POST: {}
  },
  // TRANSACTION REPORT
  [crmEndpoint.transactionReport.refund]: {
    GET: (body, params, options) => mockTransactionReportRefundGet(body, params, options)
  },
  [crmEndpoint.transactionReport.chargeback]: {
    GET: (body, params, options) => mockTransactionReportChargebackGet(body, params, options)
  },
  [crmEndpoint.transactionReport.dispute]: {
    GET: (body, params, options) => mockTransactionReportChargebackGet(body, params, options)
  },
  [crmEndpoint.transactionReport.mini]: {
    GET: (body, params, options) => mockTransactionReportMiniGet(body, params, options)
  },
  [crmEndpoint.transactionReport.authorization]: {
    GET: (body, params, options) => mockTransactionReportAuthorizationGet(body, params, options)
  },
  [crmEndpoint.transactionReport.sales]: {
    GET: (body, params, options) => mockTransactionReportSalesGet(body, params, options)
  },
  [crmEndpoint.transactionReport.summary]: {
    GET: (body, params, options) => mockTransactionReportSummaryGet(body, params, options)
  },
  [crmEndpoint.transactionReport.riskPartnerSpecialty]: {
    GET: mockTransactionReportRiskPartnerSpecialty
  },
  [crmEndpoint.transactionReport.mvbBinPerformance]: {
    GET: mockMvbBinPerformance
  },
  [crmEndpoint.refundRequest.root]: {
    GET: mockRefundRequestGet
  },
  [crmEndpoint.refundRequest.ops]: {
    POST: mockDefaultPostRes
  },
  [crmEndpoint.refundRequest.residual]: {
    POST: mockDefaultPostRes
  },
  [crmEndpoint.transactionReport.reserveDetail]: {
    GET: mockReportReserveDetailsGet
  },
  [crmEndpoint.transactionReport.reserveCurrent]: {
    GET: mockReportReserveCurrentGet
  },
  [crmEndpoint.transactionReport.batchDetails]: {
    GET: (body, params, options) => mockBatchDetailsGet(body, params, options)
  },
  [crmEndpoint.transactionReport.transactionDetails]: {
    GET: (body, params, options) => mockTransactionReportTxnDetailsGet(body, params, options)
  },
  // RISK
  [crmEndpoint.risk.alerts]: {
    GET: mockAlerts
  },
  [crmEndpoint.risk.riskCategory]: {
    GET: mockRiskCategoriesGetRes,
    PUT: mockCategoryPutRes
  },
  [crmEndpoint.risk.riskCategoryMetric]: {
    GET: mockRiskCategoryMetricGet
  },
  [crmEndpoint.risk.riskSubcategory]: {
    GET: mockRiskSubcategoryGetRes,
    PUT: (body, params, options) => mockRiskSubcategoryPutRes(body, params, options)
  },
  [crmEndpoint.risk.riskSubcategoryMetric]: {
    GET: mockRiskSubcategoryMetricGet
  },
  [crmEndpoint.risk.processingHistory]: {
    GET: (body, params, options) => mockProcessingHistoryGetRes(body, params, options)
  },
  [crmEndpoint.risk.chargebackHistory]: {
    GET: (body, params, options) => mockChargebackHistoryGetRes(body, params, options)
  },
  [crmEndpoint.risk.allTimeHistoryV2]: {
    GET: (body, params, options) => mockAllTimeHistoryV2GetRes(body, params, options)
  },
  [crmEndpoint.risk.exposure]: {
    GET: (body, params, options) => mockExposureGetRes(body, params, options)
  },
  [crmEndpoint.risk.triggerAndClosure]: {
    GET: mockTriggerAndClosure
  },
  [crmEndpoint.risk.closingEntity]: {
    GET: mockClosingEntity
  },
  [crmEndpoint.risk.processFile]: {
    POST: mockDefaultPostRes
  },
  [crmEndpoint.risk.rule]: {
    GET: (body, params, options) => mockRiskRuleGet(body, params, options),
    PUT: {},
    POST: mockDefaultPostRes
  },
  [crmEndpoint.risk.reserveHistory]: {
    GET: (body, params, options) => mockReserveHistoryGetRes(body, params, options)
  },
  [crmEndpoint.risk.achRejectHistory]: {
    GET: (body, params, options) => mockAchRejectHistoryGetRes(body, params, options)
  },
  [crmEndpoint.risk.binStatus]: {
    GET: mockBinStatusGet,
    POST: mockDefaultPostRes
  },
  [crmEndpoint.risk.merchantMonitoring]: {
    POST: mockDefaultPostRes
  },
  [crmEndpoint.risk.onHold]: {
    POST: mockDefaultPostRes
  },
  [crmEndpoint.risk.prohibitedEntities]: {
    GET: mockRiskProhibitedEntitiesGet,
    PUT: mockRiskProhibitedEntitiesPut,
    DELETE: mockDefaultPostRes
  },
  [crmEndpoint.risk.repayTransactionHold]: {
    GET: (body, params, options) => mockRepayTransactionHoldGet(body, params, options),
    POST: mockDefaultPostRes
  },
  [crmEndpoint.risk.repayTransactionHoldQa]: {
    GET: mockDefaultPostRes
  },
  [crmEndpoint.risk.repayTransactionHoldConfig]: {
    GET: (body, params, options) => mockRepayTransactionHoldConfig(body, params, options),
    POST: mockDefaultPostRes
  },
  [`${getBaseApiUrls().root}/dummyUploadLink`]: {
    PUT: {}
  },
  // Residuals
  [residualsEndpoint.midMapping]: {
    POST: (body, params, options) => mockResidualMidMappingPOSTRes(body, params, options)
  },
  [residualsEndpoint.fileInput]: {
    GET: (body, params, options) => mockUploadFilesInputGet(body, params, options),
    PUT: mockUploadFilesPut,
    DELETE: mockDefaultPostRes
  },
  [residualsEndpoint.fileOutput]: {
    GET: (body, params, options) => mockUploadFilesOutputGet(body, params, options)
  },
  [residualsEndpoint.fileProcess]: {
    GET: {},
    POST: mockDefaultPostRes
  },
  [residualsEndpoint.v2Task]: {
    GET: (body, params, options) => mockV2TaskGetRes(body, params, options),
    PUT: mockV2TaskPutRes
  },
  [residualsEndpoint.v2TaskDetail]: {
    GET: (body, params, options) => mockResidualsTaskDetailGet(body, params, options)
  },
  // CRAB
  [endpoint.crab.v1.application.root]: {
    GET: mockEmployeeCrabV1ApplicationGet,
    POST: mockDefaultPostRes
  },
  [endpoint.crab.v1.application.mpaCorrection]: {
    POST: mockDefaultPostRes
  },
  [endpoint.crab.v1.application.mpaPdfCorrection]: {
    POST: mockDefaultPostRes
  },
  [endpoint.crab.v1.application.mpaMetadata]: {
    POST: mockDefaultPostRes
  },
  [endpoint.crab.v1.application.toMpa]: {
    GET: mockCrabV1ToMpa
  },
  [endpoint.crab.v1.prevet.root]: {
    GET: mockEmployeePrevetsGet,
    POST: mockDefaultPostRes
  },
  [endpoint.crab.v1.file.applicationPackage]: {
    GET: (body, params, options) => mockBoardingApplicationFilesGet(body, params, { ...options, userType: 'employee' })
  },
  [endpoint.crab.v1.file.dummyDownloadUrl]: {
    GET: mockBoardingApplicationDownloadUrlGet
  },
  [endpoint.crab.v3.application.approve]: {
    POST: mockApplicationApproveV3
  },
  [endpoint.crab.v1.application.submitToBank]: {
    POST: mockDefaultPostRes
  },
  [endpoint.crab.v1.application.details]: {
    GET: mockEmployeeCrabV1ApplicationDetailsGet
  },
  [endpoint.crab.v1.application.mpaDetail]: {
    GET: mockCrabApplicationMpaDetailGet
  },
  [endpoint.crab.v1.application.externalCommunication]: {
    ...sharedStubDataMap[endpoint.crab.v1.application.externalCommunication],
    GET: (body, params, options) => mockCrabExternalCommunicationGet(body, params, {
      ...options,
      userType: 'employee'
    })
  },
  [endpoint.crab.v1.application.recallFromPartner]: {
    POST: mockDefaultPostRes
  },
  [endpoint.crab.v1.application.taskPend]: {
    GET: (body, params, options) => mockBoardingApplicationTaskPendGet(body, params, {
      ...options,
      userType: 'employee',
      employeeList: filterEmployeeList({ list: mockEmployeeList, allowedGroups: ['APP_REVIEW'] }),
      defaultAppReviewEmployee,
      createMockTaskList
    }),
    POST: mockDefaultPostRes,
    PUT: mockBoardingApplicationTaskPendPut
  },
  [endpoint.crab.v1.application.task]: {
    GET: mockEmployeeCrabV1ApplicationTaskGet,
    POST: mockDefaultPostRes
  },
  [endpoint.crab.v1.application.taskConfig]: {
    GET: mockApplicationTaskConfigGet,
    POST: mockDefaultPostRes
  },
  [endpoint.crab.v1.application.tasks.gdsRule]: {
    POST: mockDefaultPostRes
  },
  [endpoint.crab.v1.application.tasks.match]: {
    GET: mockEmployeeCrabV1ApplicationTaskMatchGet
  },
  [endpoint.crab.v1.application.tasks.prohibitedEntities]: {
    GET: mockEmployeeCrabV1ApplicationTaskProhibitedEntitiesGet
  },
  [endpoint.crab.v1.application.tasks.relatedPersons]: {
    GET: mockEmployeeCrabV1ApplicationTaskRelatedPersonsGet
  },
  [endpoint.crab.v1.application.tasks.websiteReview]: {
    GET: mockEmployeeCrabV1ApplicationTaskWebsiteReviewGet
  },
  [endpoint.crab.v1.application.tasks.whoisReview]: {
    GET: mockEmployeeCrabV1ApplicationTaskWhoisReviewGet
  },
  [endpoint.crab.v1.application.tasks.riskExposure]: {
    GET: mockEmployeeCrabV1ApplicationTaskRiskExposureGet
  },
  [endpoint.crab.v1.application.escalate]: {
    POST: mockDefaultPostRes
  },
  [endpoint.crab.v1.application.relationshipReclassification]: {
    POST: mockDefaultPostRes
  },
  [endpoint.crab.v1.application.requiresNewSignatureFalse]: {
    POST: mockDefaultPostRes
  },
  [endpoint.crab.v1.application.assignEmployee]: {
    POST: mockDefaultPostRes
  },
  [endpoint.crab.v1.application.negativeAction]: {
    GET: mockNegativeActionGet,
    POST: mockDefaultPostRes
  },
  [endpoint.crab.v1.application.pendReport]: {
    GET: mockApplicationPendReport
  },
  [endpoint.portfolioSummaryReport]: {
    GET: mockDefaultPostRes
  },
  // IP Whitelist Check
  [infrastructureEndpoint.isWhitelistedIp]: {
    GET: mockDefaultPostRes
  },
  [crmEndpoint.underwriting.availableMids]: {
    GET: mockAvailableMidsGet,
    POST: mockAvailableMidsPost
  },
  [crmEndpoint.repay.toRepaySftp]: {
    POST: mockDefaultPostRes
  },
  // NACHA
  [endpoint.nacha.root]: {
    GET: (body, params, options) => mockNachaGet(body, params, options),
    PUT: mockDefaultPostRes
  },
  [endpoint.nacha.detail]: {
    GET: (body, params, options) => mockNachaDetailsGet(body, params, options)
  },
  [endpoint.nacha.return]: {
    GET: (body, params, options) => mockNachaReturnsGet(body, params, options)
  },
  [endpoint.nacha.submitToBank]: {
    POST: mockDefaultPostRes
  },
  [endpoint.nacha.reject]: {
    POST: mockDefaultPostRes
  },
  [endpoint.merchant.var]: {
    GET: (body, params, options) => mockMerchantVarGet(body, params, {
      ...options,
      staticMap: mockStaticMapMerchantVar
    })
  },
  [crmEndpoint.merchant.var]: {
    POST: mockDefaultPostRes,
    PUT: mockVarPut
  },
  [endpoint.custom.settings]: {
    GET: mockCustomSettingsGet,
    POST: mockDefaultPostRes
  }
};
