import { v4 as uuidv4 } from 'uuid';
import { sharedFormFields } from '../_formFields';

import {
  capitalize,
  stateCodes,
  getRandomNumber,
  pad,
  getNow,
  isEmpty,
  formatNumber
} from '../_helpers';

// RANDOM MOCK VALUE HELPERS
export const getMockValue = (options) => {
  const {
    min = null,
    max = null,
    type = 'number',
    stringify = false, // return value as a string
    list = [],
    allowNull = false,
    isMasked = false,
    maxDecimalCount,
    length = 'short' // sentence length
  } = options || {};
  let val = '';
  if (type === 'number') {
    const minVal = min || 0;
    const maxVal = (!isEmpty(max) && (max > min) ? max : (min || 15) + 5) || 20;
    const randomNum = getRandomNumber({ min: minVal, max: maxVal });
    val = stringify ? `${randomNum}` : randomNum;
  } else if (type === 'currency') {
    const randomNum = getRandomNumber({ min: min || 0, max: max || 20 });
    val = formatNumber(randomNum, { currency: true });
  } else if (type === 'boolean') {
    val = getMockListValue([true, false]);
  } else if (type === 'date' || type === 'isoDate' || type === 'yearMonth') {
    const year = getRandomNumber({ min: min || 1990, max: max || 2019 });
    const month = pad(getRandomNumber({ min: 1, max: 12 }));
    const day = type === 'yearMonth' ? '' : `-${pad(getRandomNumber({ min: 1, max: 28 }))}`;
    const hour = pad(getRandomNumber({ min: 0, max: 23 }));
    const minute = pad(getRandomNumber({ min: 0, max: 59 }));
    const t = type === 'isoDate' ? `T${hour}:${minute}:16.950` : '';
    const value = `${year}-${month}${day}${t}`;
    val = allowNull ? getMockListValue([value, null]) : value;
  } else if (type === 'futureDate' || type === 'futureIsoDate') {
    const value = createMockFutureTimestamp(options);
    val = allowNull ? getMockListValue([value, null]) : value;
  } else if (type === 'list') {
    val = allowNull ? getMockListValue([...list, null]) : getMockListValue(list);
  } else if (type === 'string') {
    val = getRandomString();
  } else if (type === 'phone' || type === 'bePhone') {
    const areaCode = `${getRandomNumber({ min: 200, max: 999 })}-`;
    const phoneNum = `${getRandomNumber({ min: 100, max: 999 })}-${getRandomNumber({ min: 1000, max: 9999 })}`;
    const frontendPhone = areaCode.concat(phoneNum);
    val = type === 'phone' ? frontendPhone : `+1${frontendPhone.replace(/[-]/g, '')}`;
  } else if (type === 'email') {
    val = generateRandomUser(options).email;
  } else if (type === 'description') {
    val = generateRandomSentence({ length });
  } else if (type === 'url') {
    val = 'www.testingwebsite.com';
  } else if (type === 'urlRequiredProtocol') {
    val = 'https://www.testingwebsite.com';
  } else if (type === 'user') {
    val = generateRandomUser(options);
  } else if (type === 'guid') {
    val = uuidv4();
  } else if (type === 'address') {
    val = generateRandomAddress();
  } else if (type === 'ratio') {
    const maxRatioNumber = maxDecimalCount && maxDecimalCount > 2
      ? Number(`1${'0'.repeat(maxDecimalCount)}`)
      : 100;
    val = getRandomNumber({ min: 1, max: maxRatioNumber }) / maxRatioNumber;
  } else if (type === 'ssn') {
    val = isMasked
      ? `xxxxx${getRandomNumber({ min: 1000, max: 9999 })}`
      : `${getMockValue({ type: 'list', list: ['111223333', '222334444', '333445555', '444556666'] })}`;
  } else if (type === 'mid' || type === 'discoverMid') {
    // mid: default min: 12 digits, max: 15 digits
    // discoverMid: must be 15 digits
    const midMin = min || (type === 'discoverMid' ? 100000000000000 : 100000000000);
    const midMax = max || (type === 'discoverMid' ? 999999999999999 : 999999999999999);
    val = `${getRandomNumber({ min: midMin, max: midMax })}`;
  } else if (type === 'cardBrand') {
    const randomCardBrand = getMockValue({ type: 'list', list: ['DISCOVER', 'VISA', 'OTHER', 'MASTERCARD'] });
    val = randomCardBrand;
  } else if (type === 'price') {
    const dollars = getMockValue({ type: 'number' });
    const cents = getMockValue({ type: 'number', max: 99 });
    const price = Number(`${dollars}.${pad(cents)}`);
    val = stringify ? price.toFixed(2) : price;
  } else if (type === 'cardNumber') {
    const first6 = getRandomNumber({ min: 100000, max: 999999 });
    const last4 = getRandomNumber({ min: 1000, max: 9999 });
    val = `${first6}xxxxxx${last4}`;
  } else if (type === 'businessName') {
    const adjective = getMockValue({ type: 'list', list: adjectives });
    const noun = getMockValue({ type: 'list', list: nouns.plural });
    const structure = getMockValue({ type: 'list', list: ['Inc', 'LLC', 'Corp', 'LP', 'LLP'] });
    val = `${capitalize(adjective)} ${capitalize(noun)} ${structure}`;
  } else if (type === 'bankName') {
    val = getMockValue({ type: 'list', list: ['AXIOM', 'CROSS_RIVER', 'CYNGERGY'] });
  } else if (type === 'processName') {
    val = getMockValue({ type: 'list', list: ['FIRST_DATA', 'PAYROC', 'WORLD_PAY', 'RS2', 'TSYS', 'PRIORITY', 'REPAY', 'AURORA', 'NETEVIA', 'MERCHANT_INDUSTRY', 'CARDWORKS', 'TRINITY', 'MICAMP_PROCESSING_MAVERICK'] });
  } else if (type === 'paymentGateway') {
    val = getMockValue({ type: 'list', list: ['PAYEEZY', 'NMI', 'OMAHA', 'LIGHTSPEED', 'SWIPE_SIMPLE', 'MULTIPASS', 'FLUID_PAY'] });
  } else if (type === 'mcc') {
    val = getMockValue({ type: 'list', list: sharedFormFields.mccCode.list });
  } else if (type === 'accountNumber') {
    val = isMasked ? `xxxxxxxxxxxxx${getRandomNumber({ min: 1000, max: 9999 })}` : `${getRandomNumber({ min: 100000000000, max: 999999999999 })}`;
  } else if (type === 'routingNumber') {
    val = isMasked ? `xxxxx${getMockValue({ type: 'number', min: 1000, max: 9999 })}` : `${getRandomNumber({ min: 100000000, max: 999999999 })}`;
  }
  return stringify ? val.toString() : val;
};

export const getRandomString = () => {
  const stringArray = [];
  // Get a random length between 5 and 15
  const length = Math.floor(Math.random() * (15 - 5 + 1) + 5);
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  for (let i = 0; i < length; i += 1) {
    stringArray.push(alphabet[Math.floor(Math.random() * alphabet.length)]);
  }
  return stringArray.join('');
};

export const getMockLabel = (options) => {
  const {
    labelType = 'month',
    months = 24
  } = options || {};
  if (labelType === 'month') {
    const d = getNow();
    const y = d.getFullYear();
    const year = months <= 12 ? y : y - 1;
    const month = pad(months <= 12 ? months : months - 12);
    return `${year}-${month}`;
  }
  return `Unknown_Label_${months}`;
};

export const getMockListValue = (list) => {
  const i = getRandomNumber({ max: list.length });
  const item = list[i];
  return item?.value !== undefined // checking undefined to account for boolean values
    ? item.value
    : item;
};

// CHART HELPERS
export const mockDefaultChartHeader = { // default api barCharts header object from BE
  label: 'label',
  lines: [],
  biaxial: [],
  biaxialCurrency: null,
  currency: null
};

// PAGING INFO HELPERS
export const getMockPageData = (options) => {
  // returns the page data for a specific page & page size
  const {
    allData = [], // should be ALL the mock data that gets created
    pageIndex = 0,
    pageSize = 10,
    optionsCopy = {}
  } = options || {};
  let pageData = [...allData];
  if (pageIndex === 0) { // if it's the first page
    pageData = allData.slice(0, pageSize);
  } else { // not the first page, remove until we get the page we want
    while (optionsCopy.pageIndex > 0) {
      pageData.splice(0, pageSize);
      pageData = pageData.slice();
      optionsCopy.pageIndex -= 1;
    }
    pageData = pageData.slice(0, pageSize);
  }
  return pageData;
};

export const mockPagingInfo = { // default paging info for mocked endpoints
  totalNumberOfRecords: 30,
  pageIndex: 0,
  pageSize: 10
};

export const mockPagingTokenInfo = { // default paging token info for mocked endpoints
  hasMoreRecords: false,
  nextPageId: 0, // for mock data, this is just index-based
  pageSize: 10
};

export const mockDefaultPostRes = (body, params, options) => {
  // default mock data POST response for request that return 204 status (with no response body)
  const { guid = ['empty'] } = options || {};
  return { [guid]: {} };
};

export const generateRandomUser = (options) => {
  const { userType } = options || {};
  const first = [
    'John',
    'Jane',
    'Bob',
    'Frank',
    'Liz',
    'Taylor',
    'James',
    'Jesse',
    'Musashi',
    'Sasaki',
    'Shingen',
    'George'
  ];
  const last = [
    'Doe',
    'Smith',
    'Anderson',
    'Jones',
    'Swift',
    'Taylor',
    'James',
    'Miyamoto',
    'Kojiro',
    'Takeda',
    'Marooney'
  ];
  const generationList = [
    'Jr',
    'Sr',
    'I',
    'II',
    'III',
    'IV',
    'V',
    'VI',
    'VII',
    'VIII',
    'IX'
  ];
  const titleList = [
    'Mr',
    'Mrs',
    'Miss',
    'Ms',
    'Sir',
    'Dr',
    'General',
    'Professor',
    'Duke',
    'Lady',
    'Lord'
  ];
  const jobTitleList = [
    'Owner',
    'CEO',
    'Founder',
    'Managing Director',
    'President',
    'Director',
    'Principal',
    'Managing Partner',
    'Managing Member',
    'Administrator',
    'Proprietor',
    'General Manager',
    'Chairperson',
    'Director of Operations',
    'Technical Director',
    'Creative Director'
  ];
  const firstName = getMockListValue(first);
  const lastName = getMockListValue(last);
  const fullName = `${firstName} ${lastName}`;
  const title = getMockListValue(titleList);
  const jobTitle = getMockListValue(jobTitleList);
  const generation = getMockListValue(generationList);
  const email = `${firstName.toLowerCase()}${lastName.toLowerCase()}@${userType === 'external' ? 'mockexternal' : 'corviapay'}.com`;
  return {
    title,
    firstName,
    lastName,
    fullName,
    jobTitle,
    generation,
    age: getRandomNumber({ min: 21, max: 90 }),
    email
  };
};

const nouns = {
  plural: [
    'birds',
    'clocks',
    'boys',
    'plastics',
    'ducks',
    'teachers',
    'old ladies',
    'professors',
    'hamsters',
    'dogs',
    'women',
    'men',
    'children',
    'teeth',
    'feet',
    'people',
    'leaves',
    'mice',
    'geese',
    'knives',
    'wives',
    'elves',
    'loaves',
    'potatoes',
    'tomatoes',
    'cacti',
    'fungi'
  ],
  singular: [
    'bird',
    'clock',
    'boy',
    'plastic',
    'duck',
    'teacher',
    'old lady',
    'professor',
    'hamster',
    'dog',
    'woman',
    'man',
    'child',
    'tooth',
    'foot',
    'person',
    'leaf',
    'mouse',
    'goose',
    'knife',
    'wife',
    'elf',
    'loaf',
    'potato',
    'tomato',
    'cactus',
    'fungus'
  ]
};
const adjectives = [
  'beautiful',
  'lazy',
  'professional',
  'lovely',
  'dumb',
  'rough',
  'soft',
  'hot',
  'slimy',
  'adorable',
  'clean',
  'drab',
  'elegant',
  'fancy',
  'glamorous',
  'handsome',
  'long',
  'magnificent',
  'old-fashioned',
  'plain',
  'quaint',
  'sparkling',
  'ugliest',
  'unsightly',
  'angry',
  'bewildered',
  'clumsy',
  'defeated',
  'embarrassed',
  'fierce',
  'grumpy',
  'helpless',
  'itchy',
  'jealous',
  'lazy',
  'mysterious',
  'nervous',
  'obnoxious',
  'panicky',
  'repulsive',
  'scary',
  'thoughtless',
  'uptight',
  'worried'];

export const generateRandomSentence = (options) => {
  const {
    length = 'short'
  } = options || {};
  const verbs = [
    'kicked',
    'ran',
    'flew',
    'dodged',
    'sliced',
    'rolled',
    'died',
    'breathed',
    'slept',
    'killed'
  ];
  const adverbs = [
    'slowly',
    'elegantly',
    'precisely',
    'quickly',
    'sadly',
    'humbly',
    'proudly',
    'shockingly',
    'calmly',
    'passionately'
  ];
  const preposition = [
    'down',
    'into',
    'up',
    'on',
    'upon',
    'below',
    'above',
    'through',
    'across',
    'towards'
  ];
  const determiners = [
    { word: 'the' },
    { word: 'the', plural: true },
    { word: 'a' },
    { word: 'each' },
    { word: 'some', plural: true },
    { word: 'which' },
    { word: 'which', plural: true },
    { word: 'this' },
    { word: 'that' },
    { word: 'these', plural: true },
    { word: 'those', plural: true },
    // { word: 'some of', plural: true },
    // { word: 'piece of' },
    // { word: 'and others', plural: true },
    { word: 'most', plural: true },
    { word: 'all', plural: true }
  ];
  const coordinatingConjunctions = [
    'for',
    'and',
    'nor',
    'but',
    'or',
    'yet',
    'so'
  ];
  const correlativeConjunction = (w1, w2) => [
    `either ${w1} or ${w2}`,
    `neither ${w1} nor ${w2}`,
    `not only ${w1} but also ${w2}`,
    `both ${w1} and ${w2}`,
    `whether ${w1} or ${w2}`
  ];
  const subordinatingConjunctions = [
    'because',
    'as',
    'if',
    'unless',
    'in case',
    'although',
    'though',
    'since',
    'until',
    'while',
    'when',
    'where',
    'wherever',
    'before',
    'after',
    'once',
    'whenever',
    'whereas'
  ];
  const determiner1 = getMockListValue(determiners);
  const determiner2 = getMockListValue(determiners);
  const determiner3 = getMockListValue(determiners);

  let sentence = capitalize(determiner1.word);
  sentence += ` ${getMockListValue(adjectives)}`;
  sentence += ` ${getMockListValue(determiner1.plural ? nouns.plural : nouns.singular)}`;
  sentence += ` ${getMockListValue(adverbs)}`;
  sentence += ` ${getMockListValue(verbs)}`;
  if (length === 'medium' || length === 'long') {
    sentence += ` ${getMockListValue(preposition)}`;
    sentence += ` ${determiner2.word}`;
    sentence += ` ${getMockListValue(determiner2.plural ? nouns.plural : nouns.singular)}`;
    sentence += ` ${getMockListValue(subordinatingConjunctions)}`;
    sentence += ` ${getMockListValue(verbs)},`;
    sentence += ` ${getMockListValue(correlativeConjunction(getMockListValue(adverbs), getMockListValue(adverbs)))}`;
    sentence += ` ${getMockListValue(preposition)}.`;
  }
  if (length === 'long') {
    sentence += ` ${capitalize(determiner3.word)}`;
    sentence += ` ${getMockListValue(adjectives)}`;
    sentence += ` ${getMockListValue(determiner3.plural ? nouns.plural : nouns.singular)}`;
    sentence += ` ${getMockListValue(adverbs)}`;
    sentence += ` ${getMockListValue(verbs)}`;
    sentence += ` ${getMockListValue(preposition)}`;
    sentence += ` ${getMockListValue(determiner3.plural ? nouns.plural : nouns.singular)}`;
    sentence += ` ${getMockListValue(coordinatingConjunctions)}`;
    sentence += ` ${getMockListValue(adjectives)}`;
  }
  sentence += '.';
  return sentence;
};

export const generateRandomAddress = () => {
  const houseNumber = getRandomNumber({ min: 1, max: 9999 });
  const prefixDirection = getMockListValue([true, false]) ? ` ${getMockListValue(['N', 'S', 'E', 'W'])} ` : ' ';
  const streetName = getMockListValue([
    'Big Mac',
    'Dairy-Bonanza',
    'Extraterrestrial',
    'Goat Camp',
    'Pancake',
    'Stegosaurus',
    'Spaghetti',
    'Superstition',
    'Van Winkle',
    'Y-Not',
    'Yolo'
  ]);
  const streetType = getMockListValue(['Dr', 'St', 'Ave', 'Blvd', 'Cir', 'Rd', 'Ln', 'Ct']);
  const cityName = getMockListValue(['Austin', 'Dallas', 'Houston', 'Fort Worth']);
  const county = getMockListValue(['Archer', 'Denton', 'Harris', 'Travis', 'Tarrant', 'Dallam']);
  return {
    fullAddress: `${houseNumber}${prefixDirection}${streetName} ${streetType}`,
    zipCode: `${getRandomNumber({ min: 10000, max: 99999 })}`,
    stateCode: getMockListValue(stateCodes),
    county,
    cityName
  };
};

const createMockFutureTimestamp = (options) => {
  const {
    daysToAdd,
    hours,
    minutes,
    type
  } = options || {};
  const date = new Date();
  const currentDay = date.getDate();
  date.setDate(currentDay + (daysToAdd || 1));
  date.setHours(hours || 10, minutes || 30, 0, 0); // by default sets to 10:30 AM
  const isoString = (new Date(date)).toISOString();
  return type === 'futureDate' ? isoString.slice(0, 10) : isoString;
};
